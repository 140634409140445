import React, { ChangeEvent, useEffect, useState } from "react";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";
import { Link } from "react-router-dom";
import { IAdminRegister, IOrganisationRegister } from "../utils/types";
import Toast from "../components/partials/Toast";
import { useRegister, useRequestEmail } from "../redux/apis/authApi";
import storage from "../helpers/storage";
import VerifyModal from "./auth/VerifyModal";
import { toast } from "react-toastify";
import PrimaryBtn from "../components/layouts/button";

const Register = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false),
    [adminData, setAdminData] = useState<IAdminRegister>({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      phone: "",
    }),
    [organisation, setOrganisation] = useState<IOrganisationRegister>({
      name: "",
    }),
    textChange = (e: ChangeEvent<HTMLInputElement>): void => {
      let { name, value } = e.target;
      setAdminData({ ...adminData, [name]: value });
    };
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const onRegError = (err: any) => {
      console.log("register-error", err);

      if (err && err.response && err.response.data) {
        toast?.error(err?.response?.data?.message);
      }
      setLoading(false);
    },
    onRegSuccess = (reg: any) => {
      toast?.success("Registration successful");
      console.log("register-data", reg);
      if (reg) {
        toggleModal();
      }
      setLoading(false);
    };

  const {
    mutate: registerUser,
    isSuccess: isRegisterSuccess,
    isPending: isRegisterLoading,
    // isError: isRegisterError,
    // error: registerError
  } = useRegister(onRegSuccess, onRegError);

  const register = (e: React.MouseEvent<HTMLElement>) => {
    let errArr2 = [];
    if (!adminData?.email) errArr2?.push("Email is required");
    if (!adminData?.password) errArr2?.push("Password is required");
    if (!adminData?.firstName) errArr2?.push("First Name is required");
    if (!adminData?.lastName) errArr2?.push("Last Name");
    if (!adminData?.phone) errArr2?.push("Phone number is required");
    if (!organisation?.name) errArr2?.push("Business name is required");
    if (errArr2?.length > 0) return errArr2?.forEach((it) => toast.error(it));
    setLoading(true);
    console.log({ adminData, organisation });
    storage.saveEmail(adminData.email);
    registerUser({ admin: adminData, organisation: organisation });
  };

  const onRequestSuccess = (resp: any) => {
    console.log("request-data", resp);
    toast?.info(resp?.data?.data?.message);
  };

  const onRequestError = (err: any) => {
    console.log("request-data", err);
  };

  const { mutate: requestEmail } = useRequestEmail(
    onRequestSuccess,
    onRequestError
  );

  useEffect(() => {
    const usemail = storage.getEmail();
    // setStep(0)
    if (isRegisterSuccess) {
      requestEmail(usemail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegisterSuccess]);

  return (
    <>
      <Navbar />

      <main
        className="xl:px-20 lg:px-6 md:px-10 px-6  bg-center bg-no-repeat "
        style={{
          backgroundImage: `url("../../../assets/images/bg@sign-in.png")`,
        }}
      >
        <section>
          <div className="md:text-center text-left md:px-28 px-6 md:pt-16 pt-10">
            <h1 className="md:text-5xl text-[32px] pb-6 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
              Register on cebiz
            </h1>
            <p className="font-satoshimedium text-oxford-800 text-base pr-0 xl:pr- lg:pr- md:pr-0">
              Welcome! Please enter your details.
            </p>
          </div>

          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 grid-cols-1 items-end pt-12 py-36  overflow-x-hidden">
            <div className="col-span-2 xl:pl-28 lg:pl-0 md:pl-0 ">
              <form
                className=" bg-white xl:px-20 lg:px-10 md:px-10 px-6 pt-10 pb-16 rounded-[28px]"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mb-6">
                  <div className="">
                    <label
                      htmlFor="first"
                      className="block mb-4 text-base font-satoshimedium text-oxford-800"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      onChange={textChange}
                      className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      autoComplete="off"
                      required
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="last"
                      className="block mb-4 text-base font-satoshimedium text-oxford-800"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={textChange}
                      className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1  gap-4 mb-6">
                  <div className="">
                    <label
                      htmlFor="email"
                      className="block mb-4 text-base font-satoshimedium text-oxford-800"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={textChange}
                      className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      autoComplete="off"
                      required
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="phone"
                      className="block mb-4 text-base font-satoshimedium text-oxford-800"
                    >
                      Phone number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      onChange={textChange}
                      className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1  gap-4 xl:mb-6 lg:mb-6 md:mb-12 mb-12">
                  <div className="">
                    <label
                      htmlFor="password"
                      className="block mb-4 text-base font-satoshimedium text-oxford-800"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={textChange}
                      className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      autoComplete="off"
                      required
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="businessname"
                      className="block mb-4 text-base font-satoshimedium text-oxford-800"
                    >
                      Business Name
                    </label>

                    <input
                      type="text"
                      name="name"
                      onChange={(e) =>
                        setOrganisation({
                          ...organisation,
                          name: e.target.value,
                        })
                      }
                      className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>

                <PrimaryBtn
                  onClick={register}
                  loading={loading}
                  text={"Register"}
                  width="w-full"
                />
              </form>
            </div>

            <div
              className="xl:block lg:block md:hidden hidden w-[700px] h-[540px] bg-center bg-no-repeat bg-cover relative ml-auto right-[-60px] "
              style={{
                backgroundImage: `url("../../../assets/images/bg@login.png")`,
              }}
            ></div>
          </div>
        </section>
      </main>
      <VerifyModal isShow={showModal} closeModal={toggleModal} size="slg" />

      <Footer />
    </>
  );
};

export default Register;
