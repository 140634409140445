import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav
        className="bg-white border-gray-200 z-50 py-1"
        style={{ borderBottom: `1px solid #001EC5` }}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="/" className="">
            <img
              src="../../../assets/images/logo.png"
              className="h-12 mr-3"
              alt="Cebiz Logo"
            />
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400  dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white   dark:border-gray-700">
              <li>
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 text-white  md:bg-transparent md:text-blue-700 md:p-0 text-cebiz-gray-900 font-satoshi md:text-cebiz-gray-900 font-satoshi text-[20px]"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className="block py-2 pl-3 pr-4 cebiz-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-cebiz-gray-900 font-satoshi text-[20px] mx-10"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block py-2 pl-3 pr-4 cebiz-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-cebiz-gray-900 font-satoshi text-[20px]"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex  items-center flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white   dark:border-gray-700">
              <li>
                <a
                  href="https://cebiz-organization-dashboard.vercel.app/"
                  target="_blank"
                  className="block py-2 pl-3 pr-4 cebiz-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-cebiz-gray-900 font-satoshi text-[20px]"
                >
                  Sign in
                </a>
              </li>
              <li>
                <Link
                  to="/register"
                  className="block bg-cebiz-blue-900 py-2 px-8 text-white rounded-[10px] hover:bg-cebiz-blue-300 font-satoshi text-[20px]"
                >
                  Sign up
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
