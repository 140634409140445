import axios from "axios";
import { toast } from "react-toastify";

export const apiCall = async (type, url, data, getter, headers, noToast) => {
  try {
    let res;
    if (type === "get")
      res = await axios.get(url, {
        headers: {
          ...headers,
        },
      });
    if (type === "post")
      res = await axios.post(
        url,
        { ...data },
        {
          headers: {
            ...headers,
          },
        }
      );
    if (type === "put")
      res = await axios.put(
        url,
        { ...data },
        {
          headers: {
            ...headers,
          },
        }
      );
    if (type === "patch")
      res = await axios.patch(
        url,
        { ...data },
        {
          headers: {
            ...headers,
          },
        }
      );
    if (type === "delete")
      res = await axios.delete(url, {
        headers: {
          ...headers,
        },
      });
    if (type === "file") {
      res = await axios.post(
        `/api/v1/file`,
        { ...data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }

    let response = res?.data;
    if (!["file", "get", "patch"]?.includes(type))
      if (!noToast) toast.success(res?.data?.message);
    if (getter) getter(response);
    return { response };
  } catch (err) {
    if (err) console.log({ error: err.response?.data, err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    if (type && type !== "get") {
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        return { errArr: error };
      } else {
        let errMsg =
          err?.response?.data?.message ||
          err?.response?.data?.error?.[0]?.message ||
          err?.response?.data?.error?.[0]?.msg ||
          err?.message;
        return { errMsg };
      }
    }
  }
};
