import React, { useEffect, useState } from 'react'
import { IVerificationModal } from '../../utils/types'
// import Modal from '../../partials/Modal'
import { Link } from 'react-router-dom'
// import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
// import { IoCloseSharp } from 'react-icons/io5'
// import { AiOutlinePlus } from 'react-icons/ai'
import Pin from '../pin'
import storage from '../../helpers/storage'
import Modal from '../../components/partials/Modal'
import { useRequestEmail, useVerifyUser } from '../../redux/apis/authApi'
import Toast from '../../components/partials/Toast'

const VerificationModal = ({ closeModal, isShow, size }: Partial<IVerificationModal>) => {

    const PIN_LENGTH = 4

    const [flag, setFlag] = useState<string>('start')
    const [active, setActive] = useState<string>('card')
    const [step, setStep] = useState<number>(0)
    const [transferStep, setTransferStep] = useState<number>(0)
    const [cardStep, setCardStep] = useState<number>(0)
    const [toast, setToast] = useState({
        type: '',
        show: false,
        message: '',
        title: '',
        position: 'top-left'
    })
    const [email, setEmail] = useState<string | null>('')

    const onSuccess = (resp: any) => {


        const { data, message } = resp.data


        if (message === 'Email verified successfully' && data.emailVerified === true) {

            setToast({ ...toast, title: 'Successful!', type: 'success', message: 'Email verification successful', show: true })

            setTimeout(() => {
                setToast({ ...toast, show: false })
                closeX()
            }, 3000)
        }


    }

    const onError = (err: any) => {

        console.log('verify-error', err)

        if (err && err.response && err.response.data) {

            setToast({ ...toast, type: 'error', title: 'Error!', show: true, message: err.response.data.message })
            setTimeout(() => {
                setToast({ ...toast, show: false })
            }, 3000)
        }

    }

    const {
        mutate: verifyUser,
        status: verifyStatus,
        isSuccess: isVerifySuccess,
        isPending: isVerifyLoading,
        isError: isVerifyError,
        error: VerifyError
    }
        = useVerifyUser(onSuccess, onError)


    // const {
    //     mutate: requestEmail,
    //     status: requestStatus,
    //     isSuccess: isRequestSuccess,
    //     isPending: isRequestLoading,
    //     isError: isRequestError,
    //     error: RequestError
    // }
    //     = useRequestEmail(onRequestSuccess, onRequestError)


    const [pin, setPin] = useState<Array<number | undefined>>(new Array(PIN_LENGTH))

    // useEffect(() => {
    //     requestEmail(storage.getEmail())
    // }, [isRequestSuccess])


    const onPinChange = (pinEntry: number | undefined, index: number) => {

        const newPin = [...pin]
        newPin[index] = pinEntry
        setPin(newPin)

    }

    const verify = async () => {

        const payload = pin.join('')

        verifyUser({token: payload})

    }

    // const request = (e: React.MouseEvent<HTMLElement>) => {
    //     if (e) e.preventDefault()

    //     if (!email) {

    //         setToast({ ...toast, type: 'danger', title: 'Invalid!', show: true, message: 'Email field cannot be empty' })
    //         setTimeout(() => {
    //             setToast({ ...toast, show: false })
    //         }, 3000)
    //     } else {
    //         requestEmail(email)
    //     }
    // }


    const toggleToast = (e: any) => {
        if (e) e.preventDefault();
        setToast({ ...toast, show: !toast.show });
    }

    const closeX = () => {
        closeModal()
    }

    return (

        <Modal isShow={isShow}>

            <Toast
                type={toast.type}
                show={toast.show}
                message={toast.message}
                position={toast.position}
                title={toast.title}
                close={toggleToast}
            />

            <div className={`modal ${step === 0 ? 'lg h-[350px]' : 'slg h-[669px]'}  bg-white rounded-[28px]`}>

                <div className="px-8 py-6 pb-10 h-full">

                    {/* modal header */}
                    <div className='mb-3'>
                        <div className="flex justify-end pb-0">
                            <Link to='' className='flex justify-center items-center h-8 w-8 rounded-full bg-cebiz-blue-900/20 hover:bg-cebiz-blue-900/40' onClick={closeX}>
                                <span className='text-2xl pb-1 text-cebiz-blue-900'>&times;</span>
                            </Link>
                        </div>

                    </div>

                    {/* modal body */}
                    <div className='h-full'>


                        {
                            step === 0 &&

                            <div className='h-full'>
                                <Pin
                                    onPinChange={onPinChange}
                                    pin={pin}
                                    pinLength={PIN_LENGTH}
                                    onClick={verify}
                                    status={isVerifySuccess}
                                />
                            </div>
                        }
                        {/* 
                        {
                            step === 0 &&
                            <>

                                <form className="bg-white px-20 pt-10 pb-16 rounded-[28px]">
                                    <div>
                                        <label htmlFor="email" className="block mb-4 text-base font-satoshimedium text-oxford-800">Email Address</label>
                                        <input
                                            type="email"
                                            id="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                            required />

                                    </div>
                                    <Link to='' onClick={(e) => request(e)} className="w-[231px] h-[60px] items-center justify-center text-white inline-flex bg-cebiz-blue-900 hover:bg-cebiz-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-satoshimedium rounded-lg text-base text-center dark:bg-cebiz-blue-900 dark:hover:bg-cebiz-blue-800 dark:focus:ring-blue-800">
                                        {isRequestLoading ? <span className='vp-loader sm white'></span> : <span className='font-bold text-sm'>Sign Up</span>}

                                    </Link>
                                </form>

                            </>
                        } */}

                    </div>



                </div>

            </div>

        </Modal>
    )
}

export default VerificationModal