import React, { useRef } from "react";
import { IPinProps } from "../utils/types";
import { Link } from "react-router-dom";

const PIN_MIN_VALUE = 0;
const PIN_MAX_VALUE = 9;
const BACKSPACE = "Backspace";

const Pin = ({ onPinChange, pin, pinLength, onClick, status }: IPinProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const changePinFocus = (pinIndex: number) => {
    const ref = inputRefs.current[pinIndex];

    if (ref) {
      ref.focus();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    const pinNumber = Number(value.trim());

    if (isNaN(pinNumber) || value.length === 0) {
      return;
    }

    if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
      console.log("pin-length", pinLength - 1);

      onPinChange(pinNumber, index);

      if (index < pinLength - 1) {
        console.log("pin-length", pinLength - 1);

        changePinFocus(index + 1);
      }
    }
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const keyboardKeycode = e.nativeEvent.code;

    if (keyboardKeycode !== BACKSPACE) {
      return;
    }

    if (pin[index] === undefined) {
      changePinFocus(index - 1);
    } else {
      onPinChange(undefined, index);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onClick();
  };

  return (
    <>
      <div className="flex flex-col items-center h-full justify-center">
        <p className="text-[#040B1B] text-2xl font-medium capitalize text-center pb-12">
          Enter Verification Code
        </p>

        <div className="flex gap-6 justify-center mb-8">
          {Array.from({ length: pinLength }, (_, index) => (
            <input
              key={index}
              onKeyDown={(e) => onKeyDown(e, index)}
              ref={(el) => {
                if (el) {
                  inputRefs.current[index] = el;
                }
              }}
              onChange={(e) => onChange(e, index)}
              value={pin[index] || ""}
              type="password"
              className="w-12 h-12 py-3 text-center text-dark text-7xl pb-6 rounded-lg bg-white/70 border border-[rgba(78, 83, 94, 0.20)]"
            />
          ))}
        </div>

        <p className="mb-4">
          <span className="text-[#1c1c1c] font-light text-xs">
            Didn't receive OTP?{" "}
          </span>
          <Link to="" className="text-[#1c1c1c] font-normal text-xs">
            Resend{" "}
          </Link>
        </p>

        <Link to="" className="underline text-black mb-24">
          Change Email Address
        </Link>

        <Link
          to=""
          onClick={(e) => handleClick(e)}
          className="w-[231px] h-[60px] items-center justify-center text-white inline-flex bg-cebiz-blue-900 hover:bg-cebiz-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-satoshimedium rounded-[12px] text-base text-center dark:bg-cebiz-blue-900 dark:hover:bg-cebiz-blue-800 dark:focus:ring-blue-800"
        >
          {status ? (
            <span className="vp-loader sm white"></span>
          ) : (
            <span className="font-bold text-sm">Verify</span>
          )}
        </Link>
      </div>
    </>
  );
};

export default Pin;
