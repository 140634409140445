import React, { useRef } from 'react'
import { IPinProps } from '../../utils/types'

const PIN_MIN_VALUE = 0
const PIN_MAX_VALUE = 9
const BACKSPACE = 'Backspace'

const Pin = ({ onPinChange, pin, pinLength }: IPinProps) => {

    const inputRefs = useRef<HTMLInputElement[]>([])

    const changePinFocus = (pinIndex: number) => {
        const ref = inputRefs.current[pinIndex]

        if (ref) {
            ref.focus()
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {

        const value = e.target.value

        const pinNumber = Number(value.trim())

        if (isNaN(pinNumber) || value.length === 0) {
            return
        }

        if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {

            onPinChange(pinNumber, index)

            if (index < pinLength - 1) {

                changePinFocus(index + 1)

            }

        }

    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {

        const keyboardKeycode = e.nativeEvent.code

        if (keyboardKeycode !== BACKSPACE) {

            return

        }

        if (pin[index] === undefined) {

            changePinFocus(index - 1)

        }

        else {

            onPinChange(undefined, index)

        }

    }

    return (

        <>

            <p className='text-[#040B1B] text-sm font-light pr-12 pb-12'>Enter transaction 4-digit PIN-Code or use your biometrics to perform action</p>
            <div className='flex gap-6 justify-center pb-24'>


                {
                    Array.from({ length: pinLength }, (_, index) => (
                        <input
                            key={index}
                            onKeyDown={(e) => onKeyDown(e, index)}
                            ref={el => {
                                if (el) {
                                    inputRefs.current[index] = el
                                }
                            }}

                            onChange={(e) => onChange(e, index)}
                            value={pin[index] || ''}
                            type="password"
                            className='w-12 h-12 py-3 text-center text-7xl pb-6 rounded-lg bg-white/70 border border-[rgba(78, 83, 94, 0.20)]'
                        />
                    ))
                }

            </div>


        </>
    )
}

export default Pin