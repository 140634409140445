let body = {};


body.scrollToElem = (id) => {

    const elem = document.getElementById(id);

    if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
    }

}

body.changeBackground = (cn) => {

    const elem = document.querySelector('.body');

    if (elem) {
        elem.classList.add(cn);
    }

}

body.dismissBackground = (cn) => {

    const elem = document.querySelector('.body');

    if (elem) {
        elem.classList.remove(cn)
    }

}

export default body