import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { ILogin, IRegister } from "../../utils/types"

const login = async (loginData: ILogin) => {
    const response = await axios.post(`${process.env.REACT_APP_AU_URL}/login`, loginData)
    return response
}

const register = async (regData: IRegister) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/auth/register-organisation`, regData)
    return response
}

const requestEmail = async (email: any) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/auth/request-email-verification`, { email })
    return response
}

const verifyUser = async (token: any) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/auth/verify-email-account`, { token })
    return response
}

export const useRegister = (onSuccess: any, onError: any) => {

    return useMutation({
        mutationFn: (data: IRegister) => register(data),
        onSuccess: onSuccess,
        onError: onError
    })

}

export const useRequestEmail = (onSuccess: any, onError: any) => {

    return useMutation({
        mutationFn: (data: any) => requestEmail(data),
        onSuccess: onSuccess,
        onError: onError
    })

}

export const useVerifyUser = (onSuccess: any, onError: any) => {

    return useMutation({
        mutationFn: (data: any) => verifyUser(data),
        onSuccess: onSuccess,
        onError: onError
    })

}

export const useLogin = (onSuccess: any, onError: any) => {

    return useMutation({
        mutationFn: (data: ILogin) => login(data),
        onSuccess: onSuccess,
        onError: onError
    })

}
