import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PageRender from "./PageRender";
import Home from "./screens/home";
import { ToastContainer } from "react-toastify";

const Routers = () => {
  return (
    <>
      <Suspense>
        <ToastContainer position="top-right" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:page" element={<PageRender />} />
          <Route path="/:page/:id" element={<PageRender />} />
          <Route path="/:page/:id/:step" element={<PageRender />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Routers;
