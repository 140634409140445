import React from "react";
import { IBtnProps } from "../../utils/types";
import { ClipLoader } from "react-spinners";

const PrimaryBtn: React.FC<IBtnProps> = ({
  width,
  text,
  onClick,
  loading,
  bg,
}) => {
  return (
    <div>
      <button
        onClick={onClick}
        style={{
          boxShadow: " 0px 12px 25px 0px #00000040",
          backgroundColor: bg || "#001EC5",
        }}
        className={`h-14 ${
          width || "w-full"
        } rounded-xl font-satoshibold text-white font-bold text-xl `}
      >
        {loading ? <ClipLoader color="white" /> : `${text}`}
      </button>
    </div>
  );
};

export default PrimaryBtn;
