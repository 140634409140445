import React, { useEffect } from "react";
import Navbar from "../components/layouts/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/layouts/Footer";
import body from "../helpers/body";

const Contact = () => {
  //   const [contactData, setContactData] = useState({
  //     fullName: "",
  //     email: "",
  //     phoneNumber: "",
  //     message: "",
  //   });

  useEffect(() => {
    body.changeBackground("bg-gray-100");
  }, []);

  return (
    <>
      <Navbar />
      <main className=" xl:px-0 lg:px-0 md:px-0 px-6 ">
        <section className="xl:h-96 lg:h-96 md:h-96 h-80  flex items-center">
          <div className="xl:basis-2/4 lg:basis-2/4 md:basis-2/4 xl:pl-24 lg:pl-24 md:pl-12">
            <div className=" pb-6">
              <h1 className="md:text-[53px] text-[32px] pb-2 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
                Get In Touch
              </h1>
            </div>

            <p className="font-satoshilight text-oxford-800 text-base">
              Join Now To Get Started With CEBIZ, Which Can Help You Manage Your
              Money More Effectively, Stop Overspending, And Be More
              Responsible. Join The Community And Unlock Exclusive Access!!
            </p>
          </div>
        </section>

        <section className="flex pt-12 bg-white  xl:my-0 lg:my-0 md:my-0 my-6">
          <div className="xl:basis-3/4 lg:basis-full md:basis-full basis-full w-100 xl:pl-44 lg:pl-12 md:pl-12 xl:pr-40 lg:pr-40 md:pr-20">
            <form
              className="  xl:px-0 lg:px-0 md:px-0 px-6"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="mb-6">
                <label
                  htmlFor="name"
                  className="block mb-4 text-base font-satoshimedium text-oxford-800"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-4 text-base font-satoshimedium text-oxford-800"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="phone"
                  className="block mb-4 text-base font-satoshimedium text-oxford-800"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  required
                />
              </div>

              <div className=" mb-16">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-oxford-800"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows={8}
                  className=" p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-cebiz-blue-900 dark:bg-white dark:border  dark:text-oxford-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                ></textarea>
              </div>

              <Link
                to=""
                className="inline-flex text-white bg-cebiz-blue-900 hover:bg-cebiz-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-satoshimedium rounded-lg text-base px-16 py-5 xl:mb-0 lg:mb-12 md:mb-12 mb-12 text-center dark:bg-cebiz-blue-900 dark:hover:bg-cebiz-blue-800 dark:focus:ring-blue-800"
              >
                Send Message
              </Link>
            </form>
          </div>

          <div className="md:block hidden">
            <img
              src="../../../assets/images/img@contact-phone.png"
              className="relative top-[-300px]"
              width={500}
              alt=""
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Contact;
