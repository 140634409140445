import React, { useEffect, useState } from "react";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import body from "../helpers/body";
import { useLogin } from "../redux/apis/authApi";
import { IAlertProps, ILogin, IVerificationModal } from "../utils/types";
import Toast from "../components/partials/Toast";
import VerificationModal from "./auth/VerificationModal";

const Login = ({ closeModal, isShow, size }: Partial<IVerificationModal>) => {
  const PIN_LENGTH = 4;

  const navigate = useNavigate();

  const [flag, setFlag] = useState<string>("start");
  const [step, setStep] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [loginData, setLoginData] = useState<ILogin>({
    username: "",
    password: "",
  });

  const [verData, setVerData] = useState({
    email: "uridevmedia@gmail.com",
  });

  const [toast, setToast] = useState({
    type: "",
    show: false,
    message: "",
    title: "",
    position: "top-left",
  });

  const [pin, setPin] = useState<Array<number | undefined>>(
    new Array(PIN_LENGTH)
  );

  useEffect(() => {
    body.changeBackground("bg-gray-100");
  }, []);

  const onLoginSuccess = (logData: Array<any>) => {
    console.log("login data", logData);

    // if (resp.user.emailVerified) {

    //     if (!resp.user.isAdmin && resp.user.type === 'organization') {

    //         storage.saveCredentials(resp.token, resp.user._id)

    //         setToast({ ...toast, type: 'success', title: 'Successful!', show: true, message: 'User loggedin successfully' })
    //         setTimeout(() => {
    //             setToast({ ...toast, show: false })
    //             navigate('/dashboard')
    //         }, 3000)

    //     }

    // }
  };

  const onLoginError = (err: any) => {
    console.log("login error", err);

    if (err && err.response && err.response.data) {
      setToast({
        ...toast,
        type: "error",
        title: "Error!",
        show: true,
        message: err.response.data.message,
      });
      setTimeout(() => {
        setToast({ ...toast, show: false });
      }, 3000);
    } else if (err === "Email not verified") {
      // verifyEmail(verData.email)
    } else {
      setToast({
        ...toast,
        type: "error",
        title: "Error!",
        show: true,
        message: err,
      });
      setTimeout(() => {
        setToast({ ...toast, show: false });
      }, 3000);
    }
  };

  const {
    mutate: loginUser,
    status: logStatus,
    isSuccess: isLoginSuccess,
    isPending: isLoginLoading,
    isError: isLoginError,
    error: loginError,
  } = useLogin(onLoginSuccess, onLoginError);

  const [alert, setAlert] = useState<IAlertProps>({
    type: "",
    show: false,
    message: "",
  });

  const onPinChange = (pinEntry: number | undefined, index: number) => {
    const newPin = [...pin];
    newPin[index] = pinEntry;
    setPin(newPin);
  };

  const toggleModal = () => {
    // if (e) e.preventDefault()
    setShowModal(!showModal);
  };

  const toggleToast = (e: any) => {
    if (e) e.preventDefault();
    setToast({ ...toast, show: !toast.show });
  };

  const login = async (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault();

    if (!loginData.password && !loginData.username) {
      setAlert({
        ...alert,
        type: "danger",
        show: true,
        message: "All fields are required",
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    } else if (!loginData.password) {
      setAlert({
        ...alert,
        type: "danger",
        show: true,
        message: "Password is required",
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    } else if (!loginData.username) {
      setAlert({
        ...alert,
        type: "danger",
        show: true,
        message: "Password is required",
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    } else {
      loginUser(loginData);

      setTimeout(() => {
        console.log(isLoginLoading);
      }, 2000);
      console.log(isLoginLoading);
    }
  };

  const closeX = () => {
    closeModal();
    setFlag("start");

    // setSubStep(0)
  };

  return (
    <>
      <Navbar />

      <Toast
        type={toast.type}
        show={toast.show}
        message={toast.message}
        position={toast.position}
        title={toast.title}
        close={toggleToast}
      />

      <section className=" xl:pl-24 lg:pl-12 md:pl-12 pl-6 xl:pr-204 lg:pr-0 md:pr-0 pr-6">
        <div className="flex xl:py-20 lg:py-10 md:py-10 py-10 relative overflow-x-hidden">
          <div className="xl:w-7/12 lg:w-7/12 md:w-11/12 w-full xl:pl-24 lg:pl-0 md:pl-0 pl-0 ">
            <div className="xl:pb-12 lg:pb-12 md:pb-0 pb-4 xl:text-left lg:text-left md:text-center text-center">
              <h1 className="md:text-5xl text-[32px] pb-4 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
                Welcome Back
              </h1>
              <p className="font-satoshimedium text-oxford-800 text-base pr-0 xl:pr-40 lg:pr-40 md:pr-0">
                Welcome! Please enter your details.
              </p>
            </div>

            <form
              className="bg-white md:h-[572px] h-auto pt-12 w-full bg-white xl:px-20 lg:px-12 md:px-12 px-6 xl:pl-20 lg:pl-0 md:pl-0 pl-6 xl:pb-0 lg:pb-0 md:pb-0 pb-6 rounded-[28px]"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-4 text-base font-satoshimedium text-oxford-800"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  onChange={(e) =>
                    setLoginData({ ...loginData, username: e.target.value })
                  }
                  id="email"
                  className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  required
                />
              </div>

              <div className="xl:mb-24 lg:mb-12 md:mb-12 mb-12">
                <label
                  htmlFor="password"
                  className="block mb-4 text-base font-satoshimedium text-oxford-800"
                >
                  Password
                </label>
                <input
                  type="password"
                  onChange={(e) =>
                    setLoginData({ ...loginData, password: e.target.value })
                  }
                  id="password"
                  className="shadow-sm p-5 bg-white border text-oxford-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  required
                />
              </div>

              <Link
                to=""
                onClick={(e) => login(e)}
                className={`w-[231px] h-[60px] items-center justify-center text-sm font-bold text-white inline-flex bg-cebiz-blue-900 hover:bg-cebiz-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-satoshimedium rounded-lg text-base text-center ${
                  isLoginLoading ? "disabled-lt" : ""
                } `}
              >
                {isLoginLoading ? (
                  <span className="vp-loader sm white"></span>
                ) : (
                  <span className=" ">Sign In</span>
                )}
              </Link>
            </form>
          </div>

          <div className="xl:w-[700px] lg:w-[600px] md:w-[700px] w-[700px] xl:block lg:block md:hidden hidden h- ml-auto absolute -right-[170px]">
            <div className="w-full">
              <img
                src="../../../assets/images/img@signin.png"
                className=""
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <VerificationModal
        isShow={showModal}
        closeModal={toggleModal}
        size="slg"
      />

      <Footer />
    </>
  );
};

export default Login;
