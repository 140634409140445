import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <div className="bg-cebiz-blue-800 pt-8 pb-32 xl:px-0 lg:px-12 md:px-12 px-6">

        <div className="max-w-screen-xl mx-auto">

          <div className="grid xl:gap-28 lg:gap-28 md:gap-12 md:gap-6 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-1">

            <div className=''>

              <div className="mb-4">

                <img src="../../../assets/images/img@logo-white.png" width={76.79} alt="" />

              </div>

              <p className='text-white font-satoshimedium text-base leading-8'>Taking control of your finances with this, and saving for your future will be made easier with CEBIZ mobile app</p>

            </div>

            <div className='mt-8 xl:justify-self-center lg:justify-self-center md:justify-self-center'>

              <h3 className="text-white font-satoshibold text-xl">Quick Menu</h3>

              <div className="grid xl:gap-16 lg:gap-16 md:gap-0 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1">

                <ul className="font-medium flex flex-col mt-4 rounded-lg ">

                  <li>
                    <Link to='/' className="block py-1 text-white font-satoshimedium">Home</Link>
                  </li>
                  <li>
                    <Link to='/about-us' className="block py-1 text-white font-satoshimedium">About Us</Link>
                  </li>

                </ul>

                <ul className="font-medium flex flex-col md:mt-4 rounded-lg ">

                  <li>
                    <Link to='/login' className="block py-1 text-white font-satoshimedium">Sign In</Link>
                  </li>
                  <li>
                    <Link to='/register' className="block py-1 text-white font-satoshimedium">Sign Up</Link>
                  </li>

                </ul>

              </div>

            </div>


            <div className='mt-8 md:justify-self-end '>

              <h3 className="text-white font-satoshibold text-xl">Contact</h3>

              <div className="grid grid-cols-2">

                <ul className="font-medium flex flex-col mt-4 rounded-lg ">

                  <li>
                    <a href='tel:07032490411' className="block py-1 text-white font-satoshimedium">07032490411</a>
                  </li>
                  <li>
                    <a href='mailto:Info@Cebiz.co' className="block py-1 text-white font-satoshimedium">Info@Cebiz.co</a>
                  </li>

                </ul>

              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default Footer