import React, { useState } from "react";
import Navbar from "../components/layouts/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/layouts/Footer";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<any>(false);

  const features = [
    {
      title: "saving management",
      desc: "make it easy for business organisations to manage the savings of their employees and also to keep track of all the members of the organisation.",
    },
    {
      title: "Financial management",
      desc: "Helps plan, organize, and control all transactions in your business.",
    },
    {
      title: "Staff management",
      desc: "We provide chargeless transactions, which are all of our transactions without any fees or charges for savings, wallet-to-wallet transfers, bill payment, etc.",
    },
    {
      title: "Payroll Management",
      desc: "CEBIZ mobile app help business organisations and cooperative society in payroll management",
    },
  ];

  const savingSteps = [
    {
      title: "Sign Up",
      desc: "Join the community and unlock exclusive access! Sign up now!",
      icon: "../../../assets/icons/icon@signup.svg",
      bg: "#001EC51A",
    },
    {
      title: "Verify",
      desc: "Verify your account to unlock exclusive features and enhance your security.",
      icon: "../../../assets/icons/icon@verify.png",
      bg: "#0440211A",
    },
    {
      title: "Start Saving",
      desc: "Start Saving Today: Invest in Your Future and Achieve Your Financial Goals.",
      icon: "../../../assets/icons/icon@wallet.png",
      bg: "#C100AB1A",
    },
  ];

  const faqs = [
    {
      title: "How do I fund my wallet?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "How do I withdraw my money?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "Do I have to be in an organisation to register?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "Can I withdraw my savings at any time?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "What do I need to open an account with cebiz pay app?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "Why do you need my BVN?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "Is there a minimum balance?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "Is my money safe with Cebiz pay?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "Will I get account statements?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
    {
      title: "What should I do if I need help?",
      desc: "We ask you to confirm your BVN so we can be sure no one is pretending to be you. Once you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.Note : we won't use your BVN to access your bank account(s). ",
    },
  ];

  const toggleFaq = (index: number) => {
    setCurrentIndex(index);
    if (isOpen === false) {
      setIsOpen(true);
    } else {
      if (currentIndex === index) {
        setCurrentIndex(index);

        setIsOpen(!isOpen);
      }
    }
  };

  return (
    <>
      <Navbar />

      {/* banner */}
      <section className="grid xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 h-screen">
        <div className="flex h-full items-center xl:order-1 lg:order-2 md:order-2 order-2 px-6 xl:px-0 lg:px-12 md:px-12">
          <div className="xl:pl-24 lg:pl-0 md:pl-0 pl-0 pb-16 xl:pt-0 lg:pt-12 md:pt-12 pt-0">
            <h1 className="md:text-[63px] text-2xl pb-6 font-satoshiblack capitalize text-oxford-900 leading-tight">
              There's no wellness without financial wellness
            </h1>
            <p className="font-satoshi text-oxford-900 md:text-[20px] text-sm md:pr-32 pr-0 leading-tight">
              You can now conduct transactions with our new platform from
              anywhere at any time
            </p>
            <div className="mt-16">
              <Link
                to=""
                className="shadow-xl btn uppercase bg-cebiz-blue-900 hover:bg-cebiz-blue-900/90 text-white text-base rounded-xl"
              >
                Get started
              </Link>
            </div>
          </div>
        </div>

        <div className=" xl:order-2 lg:order-1 md:order-1 order-1 md:overflow-hidden md:w-full w-fit md:block hidden">
          <img
            src="../../../assets/images/img@home-banner.png"
            className="xl:relative lg:static md:static static w-fit top-[-150px] right-[-125px] z-[-1] overflow-hidden"
            alt=""
          />
        </div>

        <div
          className=" md:order-2 order-1 md:w-full w-full md:hidden block bg-center bg-cover bg-no-repeat h-[400px]"
          style={{
            backgroundImage: `url("../../../assets/images/img@home-banner.png")`,
          }}
        ></div>
      </section>

      {/* why */}
      <section className="xl:py-36 lg:py-36 md:py-16 py-0 md:mb-0 mb-6 xl:flex lg:flex md:block flex-row block md:bg-gray-100 w-full px-6 md:px-0">
        <div className="xl:basis-1/2 lg:basis-1/2 md:basis-full basis-full bg-cebiz-blue-900 md:h-[715px] h-fit xl:rounded-3xl lg:rounded-3xl md:rounded-none rounded-xl overflow-hidden xl:relative lg:relative md:static static left-[-25px] overflow-hidden">
          <img src="../../../assets/images/bg@phone.png" alt="" />
        </div>

        <div className="md:basis-2/4 basis-full flex items-center xl:mt-0 lg:mt-0 md:mt-12 mt-9  xl:px-0 lg:px-0 md:px-12  ">
          <div className="xl:pl-24 lg:pl-16 md:pl-0 pl-0 pb-16">
            <h1 className="md:text-[53px] text-xl pb-6 md:leading-[4rem] xl:w-[400px] lg:w-[400px] md:w-[400px] w-full font-satoshibold capitalize text-oxford-900">
              Why You Have To Choose Us
            </h1>
            <p className="font-satoshi text-oxford-800 md:text-base text-sm xl:pr-32 lg:pr-12 md:pr-0 pr-0 md:leading-loose leading-loose">
              Proper financial management helps the growth of an organisation.
              This requires discipline, and financial management experience
              which many people lack. Cebiz software helps you automate your
              financial obligations and organisation activities such as payment
              of staffs, inventory management, Human resource management,
              savings for members within the organisation Bill payments etc.
              CEBIZ assists organisation and individuals to reach their goals
            </p>
          </div>
        </div>
      </section>

      {/* Impact */}
      <section className="xl:flex lg:block md:block xl:px-0 lg:px-12 md:px-8 px-7 xl:py-0 lg:py-16 md:py-16 py-8 block min-h-[451px] flex-row bg-slate-200 w-screen">
        <div className=" xl:mb-0 lg:mb-0 md:mb-10 mb-5">
          <div className="xl:block lg:hidden md:hidden hidden md:w-[180px] w-full bg-cebiz-blue-900 h-[201px] rounded-tl-3xl"></div>

          <img
            src="../../../assets/images/img@impact.png"
            className="xl:relative lg:static md:static static xl:w-[360px] lg:w-auto xl:mx-0 lg:mx-auto md:mx-0 mx-0 md:w-full w-full xl:h-[381px] lg:h-[700px] md:h-auto h-auto left-10 -top-[65px] xl:pb-0 lg:pb-12 md:pb-0 pb-0"
            alt=""
          />
        </div>

        <div className="self-center xl:ml-20 lg:ml-0 md:ml-0 ml-0 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7">
          <div className="w-full xl:w-[290px] lg:[w-290px] md:w-full bg-white rounded-[20px] px-4 py-5 pb-4 border-[0.5px] border-[#C100AB] md:h-[351px] h-fit">
            <div>
              <p className="font-satoshi text-oxford-800 md:text-base text-sm">
                Impact
              </p>
              <h1 className="md:text-[40px] text-[32px] pb-4 leading-[3rem] font-satoshibold capitalize text-oxford-900">
                100+
              </h1>
            </div>

            <p className="font-satoshilight text-oxford-800 md:text-base text-xs">
              "Together, we can make a difference. Let's create a positive
              impact and leave a lasting legacy. "Our three-pronged corporate
              responsibility and sustainability (CRS) approach—citizenship,
              stakeholder management, and impact management—is heavily
              influenced by our commitment to nation-building.
            </p>
          </div>

          <div className=" w-full xl:w-[290px] lg:[w-290px] md:w-full bg-white rounded-[20px] px-4 py-5 border-[0.5px] border-[#058041]  md:h-[351px] h-fit">
            <div>
              <p className="font-satoshi text-oxford-800 md:text-base text-sm ">
                Customer
              </p>
              <h1 className="md:text-[40px] text-[32px] pb-4 leading-[3rem] font-satoshibold capitalize text-oxford-900">
                70+
              </h1>
            </div>

            <p className="font-satoshilight text-oxford-800 md:text-base text-xs ">
              With fractional shares on CEBIZ, we're delivering trusted
              international savings alternatives to every African, offering any
              organisation with a CEBIZ account the chance to grow their money
              anywhere in the globe.
            </p>
          </div>

          <div className=" w-full xl:w-[290px] lg:[w-290px] md:w-full bg-white rounded-[20px] px-4 py-5 pb-6  border-[0.5px] border-[#185EFF]  md:h-[351px] h-fit">
            <div>
              <p className="font-satoshi text-oxford-800 md:text-base text-sm">
                Growth
              </p>
              <h1 className="md:text-[40px] text-[32px] pb-4 leading-[3rem] font-satoshibold capitalize text-oxford-900">
                20%
              </h1>
            </div>

            <p className="font-satoshilight text-oxford-800 md:text-base text-xs">
              Lorem ipsum dolor sit amet consectetur. A tristique tempor
              ultrices.Lorem ipsum dolor sit amet consectetur. A tristique
              tempor ultrices.
            </p>
          </div>
        </div>
      </section>

      {/* features */}
      <section className="md:pt-36 pt-20 md:pb-4 pb-16 xl:px-0 lg:px-12 md:px-12 px-6 bg-gray-100">
        <div className="xl:px-24 lg:px-12 md:px-12 px-0 mx-auto px-0 pb-6">
          <h1 className="md:text-[53px] text-[32px] pb-2 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
            All features you need <br /> in one place
          </h1>
        </div>

        <div className="xl:pl-24 lg:pl-0 md:pl-0 pl-0 flex xl:flex-row lg:flex-col md:flex-col flex-col items-start">
          <div className="md:basis-2/4 basis-full xl:order-1 lg:order-2 md:order-2 order-2">
            <p className="font-satoshilight text-oxford-800 xl:text-base lg:text-2xl md:text-base text-sm md:pr-20 pr-0 pb-6">
              "Together, we can make a difference. Let's create a positive
              impact and leave a lasting legacy. "Our three-pronged corporate
              responsibility and sustainability (CRS) approach—citizenship,
              stakeholder management, and impact management—is heavily
              influenced by our commitment to nation-building.
            </p>

            <div className=" bg-white border-[0.5px] border-stoke-100 rounded-[19px]">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="md:px-10 px-5 feature last:border-b-0 first:pt-8 first:pb-6 last:pb-8 last:pt-4 py-6 border-b-[0.5px] border-stoke-100"
                >
                  <h3 className="font-satoshibold text-oxford-900 md:text-2xl text-base capitalize pb-3">
                    {feature.title}
                  </h3>
                  <p className="font-satoshilight md:text-base text-sm">
                    {feature.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="md:basis-2/4 basis-full xl:order-2 lg:order-1 md:order-1 order-1">
            <div className="xl:pl-16 lg:pl-16 md:pl-0 pl-0 md:pb-16 pb-8">
              <img src="../../../assets/images/bg@features.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* savings */}
      <section className="md:py-16 py-10">
        <div className="xl:px-24 lg:px-12 md:px-12 px-0 mx-auto">
          <div className="md:px-0 px-6 pb-6">
            <h1 className="md:text-[53px] text-[32px] pb-2 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
              Saving Today, For A <br /> Better Tomorrow.
            </h1>
          </div>

          <div
            className="md:hidden block bg-center h-[231px] bg-no-repeat bg-cover md:rounded-[20px] rounded-md"
            style={{
              backgroundImage: `url("../../../assets/images/bg@savings.png")`,
            }}
          ></div>

          <div className="flex md:px-0 px-6 md:pt-0 pt-6">
            <div className="xl:basis-2/4 lg:basis-full md:basis-full basis-full">
              <p className="font-satoshilight text-oxford-800 md:text-base text-sm md:pr-20 pr-0 md:pb-6">
                Start building your financial future with smart savings habits.
                " Saving money is a habit that must be developed over time.
                Relax while CEBIZ assists you in forming the habit. You'll
                notice a growth in your savings over time. Start building your
                financial future with smart savings habits.
              </p>
            </div>
          </div>

          <div
            className="md:block hidden bg-center h-[450px] bg-no-repeat bg-cover md:rounded-[20px] rounded-md"
            style={{
              backgroundImage: `url("../../../assets/images/bg@savings.png")`,
            }}
          ></div>

          <div className="xl:mt-28 lg:mt-12 md:mt-28 mt-10 md:px-0 px-6">
            <div className="grid xl:gap-12 lg:gap-5 md:gap-12 gap-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 grid-cols-1 w-full">
              {savingSteps.map((step, index) => (
                <div
                  className=" border-[0.5px] border-[#001ec580] md:rounded-[20px] rounded-xl xl:py-10 lg:py-5 md:py-5 py-4 md:px-8 px-4"
                  key={index}
                >
                  <div
                    className={`md:h-10 h-8 md:w-[50px] w-[35px] flex items-center justify-center md:rounded-lg rounded-md xl:mb-10 lg:mb-10 md:mb-5 mb-3`}
                    style={{ backgroundColor: `${step.bg}` }}
                  >
                    <img src={step.icon} className="md:w-6 w-4" alt="" />
                  </div>
                  <h3 className="font-satoshibold md:text-2xl text-base text-cebiz-blue-900 xl:mb-8 lg:mb-4 md:mb-4  mb-3">
                    {step.title}
                  </h3>
                  <p className="font-satoshilight md:text-base text-sm text-black xl:mb-8 lg:mb-0 md:mb-0 mb-2">
                    {step.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Manage */}
      <section className="xl:py-20 lg:py-20 md:py-20 py-12 xl:pt-48 lg:pt-32 md:pt-32 pt-32 overflow-x-hidden  bg-[#F6F7F9]">
        <div className="xl:flex lg:flex md:block block h-[642px] items-end bg-[#001ec50d] xl:relative lg:relative md:static static left-[60px]">
          <div
            className="md:block relative hidden xl:w-[850px] lg:w-[850px] md:w-[930px] w-full xl:h-[719px] lg:h-[719px] md:h-[850px] h-[350px] bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url("../../../assets/images/bg@manage.png")`,
            }}
          ></div>

          <div
            className="md:hidden block md:w-[850px] w-[450px] md:h-[719px] h-[400px] bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url("../../../assets/images/bg@manage-mobile.png")`,
            }}
          ></div>

          <div className="xl:basis-3/4 lg:basis-3/4 md:basis-full basis-full flex items-center justify-center xl:mt-0 lg:mt-0 md:mt-12 mt-9">
            <div className=" pb-16 md:px-16 px-6 ">
              <h1 className="md:text-[53px] text-[32px] xl:w-[500px] lg:w-[500px] md:w-full w-full pb-6 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
                Manage Your Money Today Easily With Our Services
              </h1>
              <p className="font-satoshi text-oxford-800 text-base xl:pr-32 lg:pr-32 md:pr-0 pr-0">
                Proper financial management helps the growth of an organisation.
                This requires discipline, and financial management experience
                which many people lack. Cebiz software helps you automate your
                financial obligations and organisation activities such as
                payment of staffs, inventory management, Human resource
                management, savings for members within the organisation Bill
                payments etc. CEBIZ assists organisation and individuals to
                reach their goals
              </p>
              <div className="md:mt-16 mt-10">
                <Link
                  to=""
                  className="shadow-xl btn uppercase bg-cebiz-blue-900 hover:bg-cebiz-blue-900/90 text-white text-base rounded-xl"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faqs */}
      <div className="md:py-16 py-5 md:pb-40 pb-20 pt-4 bg-[#F6F7F9]">
        <div className="md:px-24 px-6 md:pb-28 pb-12 mx-auto">
          <div className=" md:pb-16 pb-8">
            <h1 className="md:text-[53px] text-[32px] md:text-center pb-2 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
              Frequently Asked Questions
            </h1>
          </div>

          <div className="">
            <div className=" w-full">
              {faqs.map((faq, index) => (
                <div
                  className={` xl:max-w-screen-lg lg:max-w-screen-lg md:max-w-screen-2xl w-full mx-auto ${
                    currentIndex === index
                      ? "md:cshadow shadow-lg"
                      : "shadow-lg"
                  } rounded-[10px] md:py-8 py-4 xl:px-16 lg:px-16 md:px-12 px-5 mb-6`}
                  key={index}
                >
                  <div className="flex items-start">
                    <h3 className="font-satoshibold md:text-2xl text-base leading-tight text-oxford-900">
                      {faq.title}
                    </h3>
                    <button
                      onClick={() => toggleFaq(index)}
                      className="md:h-10 h-6 md:w-[50px] w-[30px] flex items-center justify-center rounded-lg bg-[#001ec512] ml-auto"
                    >
                      <img
                        src={`${
                          currentIndex === index
                            ? "../../../assets/images/img@faq-close.png"
                            : "../../../assets/images/img@faq-open.png"
                        } `}
                        className="md:w-6 w-3"
                        alt=""
                      />
                    </button>
                  </div>

                  {currentIndex === index && isOpen && (
                    <p className="  font-satoshilight md:text-base text-sm text-black mt-4">
                      {faq.desc}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-12 bg-white">
          <div className="flex items-center justify-center ">
            <div className="text-center px-8 w-[655px]">
              <h1 className="md:text-[53px] text-[32px] pb-6 leading-[4rem] font-satoshibold capitalize text-oxford-900">
                Contact us
              </h1>
              <p className="font-satoshilight text-oxford-800 md:text-base text-sm">
                Start building your financial future with smart savings habits."
                Saving money is a habit that must be developed over time. Relax
                w
              </p>
              <div className="pt-12">
                <Link
                  to=""
                  className="shadow-xl btn uppercase bg-cebiz-blue-900 hover:bg-cebiz-blue-900/90 text-white text-base rounded-xl"
                >
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
