let storage = {}

storage.saveCredentials = (token) => {
    localStorage.setItem('token', token);
    // localStorage.setItem('userId', id);
}

storage.saveEmail = (email) => {
    localStorage.setItem('email', email);
}

storage.checkToken = () => {
    return localStorage.getItem('token') ? true : false;
}

storage.checkEmail = () => {
    return localStorage.getItem('email') ? true : false;
}
storage.getToken = () => {
    return localStorage.getItem('token');
}
storage.getEmail = () => {
    return localStorage.getItem('email');
}

storage.saveNavLink = (val) => {
    localStorage.setItem('recent-link', JSON.stringify(val));
}

storage.getNavLink = () => {
    return JSON.parse(localStorage.getItem('recent-link'));
}

storage.saveNavPage = (val) => {
    localStorage.setItem('recent-page', JSON.stringify(val));
}

storage.getNavPage = () => {
    return JSON.parse(localStorage.getItem('recent-page'));
}


storage.copyCode = (code) => {

    if (code !== '' && code !== undefined && typeof (code) === 'string') {
        navigator.clipboard.writeText(code);
        return true;
    } else {
        return false;
    }
}

export default storage