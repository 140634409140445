import React, { useEffect } from 'react'
import { IAlertProps } from '../../utils/types';


const Alert = ({ show, message, type }: Partial<IAlertProps>) => {

    useEffect(() => {

    }, [])

    const getIcon = (type: string | undefined) => {
        let ic = 'fe-info';

        if(type === 'info'){
            ic = 'fe-info';
        }else if(type === 'warning'){
            ic = 'fe-alert-triangle'
        }else if(type === 'danger'){
            ic = 'fe-alert-octagon'
        }else if(type === 'success'){
            ic = 'fe-check-circle'
        }

        return ic;
    }

    return (
        <>
            <div className={`alert alert-${type} flex items-center ${show === false ? 'hidden' : ''}`} role="alert">

            <div><span className={`fe ${getIcon(type)} fs-17 ui-relative`} style={{ top: '1px' }}></span></div>

                <div className="pl-2 leading">
                    <div className="message font-dm text-sm leading">
                        {message ? message : 'No message'}
                    </div>
                </div>

            </div>
        </>
    )

}

export default Alert