import React, { useEffect, useRef, useState } from "react";
import { IVerificationModal } from "../../utils/types";
import { Link, useNavigate } from "react-router-dom";
import Pin from "../pin";
import storage from "../../helpers/storage";
import Modal from "../../components/partials/Modal";
import { useRequestEmail, useVerifyUser } from "../../redux/apis/authApi";
import Toast from "../../components/partials/Toast";

let currentOTPIndex: number = 0;

const VerifyModal = ({
  closeModal,
  isShow,
  size,
}: Partial<IVerificationModal>) => {
  const navigate = useNavigate();

  const PIN_LENGTH = 4;

  const inputRef = useRef<HTMLInputElement>(null);

  const [step, setStep] = useState<number>(0);

  const [toast, setToast] = useState({
    type: "",
    show: false,
    message: "",
    title: "",
    position: "top-left",
  });
  const [email, setEmail] = useState<string | null>("");
  const [otp, setOtp] = useState<string[]>(new Array(PIN_LENGTH).fill(""));
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);

  const onSuccess = (resp: any) => {
    console.log(resp);

    const { data, message } = resp.data;

    if (
      message === "Email verified successfully" &&
      data.emailVerified === true
    ) {
      setToast({
        ...toast,
        title: "Successful!",
        type: "success",
        message: "Email verification successful",
        show: true,
      });

      setTimeout(() => {
        setToast({ ...toast, show: false });
        navigate("/login");
      }, 3000);
    }
  };

  const onError = (err: any) => {
    console.log("verify-error", err);

    if (err && err.response && err.response.data) {
      setToast({
        ...toast,
        type: "error",
        title: "Error!",
        show: true,
        message: err.response.data.message,
      });
      setTimeout(() => {
        setToast({ ...toast, show: false });
      }, 3000);
    }
  };

  const onRequestSuccess = (resp: any) => {
    const { data, message } = resp.data;

    setToast({
      ...toast,
      title: "Resend Token!",
      type: "success",
      message: "An email has been sent to your mail, check your inbox",
      show: true,
    });

    setTimeout(() => {
      setToast({ ...toast, show: false });
      navigate("https://cebiz-organization-dashboard.vercel.app/");
    }, 3000);
  };

  const onRequestError = (err: any) => {
    console.log("request-error", err);

    if (err && err.response && err.response.data) {
      setToast({
        ...toast,
        type: "error",
        title: "Error!",
        show: true,
        message: err.response.data.message,
      });
      setTimeout(() => {
        setToast({ ...toast, show: false });
      }, 3000);
    }
  };

  const {
    mutate: verifyUser,
    status: verifyStatus,
    isSuccess: isVerifySuccess,
    isPending: isVerifyLoading,
    isError: isVerifyError,
    error: VerifyError,
  } = useVerifyUser(onSuccess, onError);

  const {
    mutate: requestEmail,
    status: requestStatus,
    isSuccess: isRequestSuccess,
    isPending: isRequestLoading,
    isError: isRequestError,
    error: RequestError,
  } = useRequestEmail(onRequestSuccess, onRequestError);

  useEffect(() => {
    if (isRequestSuccess) {
      isShow = true;
    }
  }, [isRequestSuccess]);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOtp: string[] = [...otp];
    newOtp[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) {
      setActiveIndex(currentOTPIndex - 1);
    } else {
      setActiveIndex(currentOTPIndex + 1);
    }
    setOtp(newOtp);
  };

  const onKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    currentOTPIndex = index;

    if (key === "Backspace") {
      setActiveIndex(currentOTPIndex - 1);
    }
  };

  const onPaste = (e: any) => {
    const value = e.clipboardData.getData("text");
    const updatedValue = value.toString().split("").slice(0, 4);
    const focusedInput = e.target.parentNode.querySelector("input:focus");
    if (focusedInput) {
      focusedInput.blur();
    }

    if (isNaN(value)) return false;

    setOtp(updatedValue);
  };

  const verify = async (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault();

    const payload = otp.join("");

    console.log("otp", otp);

    verifyUser(payload);
  };

  const resend = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault();

    requestEmail(storage.getEmail());
  };

  const toggleToast = (e: any) => {
    if (e) e.preventDefault();
    setToast({ ...toast, show: !toast.show });
  };

  const closeX = () => {
    closeModal();
    // setSubStep(0)
  };

  return (
    <Modal isShow={isShow}>
      <Toast
        type={toast.type}
        show={toast.show}
        message={toast.message}
        position={toast.position}
        title={toast.title}
        close={toggleToast}
      />

      <div
        className={`modal ${
          step === 0 ? "lg h-[480px]" : "slg h-[669px]"
        }  bg-white rounded-[28px]`}
      >
        <div className="px-8 py-6 pb-10 h-full">
          {/* modal header */}
          <div className="mb-3">
            <div className="flex justify-end pb-0">
              <Link
                to=""
                className="flex justify-center items-center h-8 w-8 rounded-full bg-cebiz-blue-900/20 hover:bg-cebiz-blue-900/40"
                onClick={closeX}
              >
                <span className="text-2xl pb-1 text-cebiz-blue-900">
                  &times;
                </span>
              </Link>
            </div>
          </div>

          {/* modal body */}
          <div className="h-full">
            <div className="flex flex-col items-center h-full justify-center">
              <p className="text-[#040B1B] text-2xl font-medium capitalize text-center pb-12">
                Enter Verification Code
              </p>

              <div className="flex gap-6 justify-center mb-8">
                {otp.map((_, index) => (
                  <React.Fragment key={index}>
                    <input
                      ref={index === activeIndex ? inputRef : null}
                      onKeyDown={(e) => onKeyDown(e, index)}
                      onChange={(e) => onChange(e)}
                      onPaste={(e) => onPaste(e)}
                      value={otp[index]}
                      type="password"
                      maxLength={1}
                      className="w-12 h-12 pb-2 flex items-center text-center text-dark text-5xl rounded-lg bg-white/70 border border-[rgba(78, 83, 94, 0.20)]"
                      autoComplete="off"
                    />
                  </React.Fragment>
                ))}
              </div>

              <p className="mb-4">
                <span className="text-[#1c1c1c] font-light text-xs">
                  Didn't receive OTP?{" "}
                </span>
                <Link
                  onClick={(e) => resend(e)}
                  to=""
                  className="text-[#1c1c1c] font-normal text-xs"
                >
                  Resend{" "}
                </Link>
              </p>

              <Link to="" className="underline text-black mb-10">
                Change Email Address
              </Link>

              <Link
                to=""
                onClick={(e) => verify(e)}
                className="w-[231px] h-[60px] mb-12 items-center justify-center text-white inline-flex bg-cebiz-blue-900 hover:bg-cebiz-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-satoshimedium rounded-[12px] text-base text-center dark:bg-cebiz-blue-900 dark:hover:bg-cebiz-blue-800 dark:focus:ring-blue-800"
              >
                {isVerifyLoading ? (
                  <span className="vp-loader sm white"></span>
                ) : (
                  <span className="font-bold text-sm">Verify</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyModal;
