import React from "react";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <Navbar />

      <main className="bg-[#001EC50D]">
        <section>
          <div
            className="xl:h-[90vh] lg:h-[70vh] md:h-[90vh] md:block hidden relative bg-bottom bg-no-repeat bg-cover w-screen"
            style={{
              backgroundImage: `url("../../../assets/images/bg@about.png")`,
            }}
          >
            <div className="absolute top-0">
              <div className="h-[220px] ml-44 w-[62px] bg-white"></div>

              <div className="h-[411px] mb-8 bg-white w-[622px] ml-6 rounded-[30px]">
                <div className=" px-16 pt-12 w-[655px]">
                  <h1 className="text-5xl pb-6 leading-[4rem] font-satoshibold capitalize text-oxford-900">
                    Funds management, made easycebiz
                  </h1>
                  <p className="font-satoshilight text-oxford-800 xl:text-base lg:text-xl text-base pr-0 md:pr-40">
                    Expertly navigating the world of finance to help you achieve
                    your Savings goals.
                  </p>
                  <div className="pt-12">
                    <Link
                      to=""
                      className="shadow-xl btn uppercase bg-cebiz-blue-900 hover:bg-cebiz-blue-900/90 text-white text-base rounded-xl"
                    >
                      Get started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="h-[90vh] md:hidden block bg-bottom bg-no-repeat bg-cover w-full">
            <div
              className="bg-no-repeat bg-cover bg-center h-[40vh]"
              style={{
                backgroundImage: `url("../../../assets/images/bg@about.png")`,
              }}
            ></div>

            <div className="mb-8 bg-white w-full px-6 pt-6 pb-16">
              <h1 className="text-[32px] text pb-6 leading-tight font-satoshibold capitalize text-oxford-900">
                Funds management, made easycebiz
              </h1>
              <p className="font-satoshilight text-oxford-800 text-base pr-0 md:pr-40">
                Expertly navigating the world of finance to help you achieve
                your Savings goals.
              </p>
              <div className="pt-7">
                <Link
                  to=""
                  className="shadow-xl btn uppercase bg-cebiz-blue-900 hover:bg-cebiz-blue-900/90 text-white text-base rounded-xl"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="xl:h-[415px] lg:h-[600px] md:h-[600px] h-[600px] ">
          {/* <img src="../../../assets/images/img@curve.png" width={290} alt="" className="xl:block lg:block md:hidden hidden relative left-[-120px] z-[-1] top-[200px]" /> */}

          <div className=" xl:pt-0 lg:pt-0 md:pt-16 pt-16  xl:px-0 lg:px-0 md:px-12 px-6 xl:h-[415px] lg:h-[600px] md:h-[600px] h-[600px] xl:relative lg:relative md:static static md:z-50 bg-cebiz-blue-900 flex xl:flex-row lg:flex-row md:flex-col flex-col items-center overflow-y-hidden">
            <div className="xl:text-center lg:text-left md:text-center text-left xl:basis-3/4 lg:basis-full md:basis-full w-full xl:pl-24 lg:pl-12 md:pl-0 pl-0 xl:pr-36 lg:pr-36 md:pr-0 md:pb-0 pb-6">
              <h1 className="md:text-5xl text-[32px] pb-6 md:leading-[4rem] leading-tight font-satoshibold capitalize text-white xl:pr-24 lg:pr-0 md:pr-0">
                The perfect solution didn't exist so we created one.
              </h1>
              <p className="font-satoshilight text-white xl:text-base lg:text-2xl md:text-base text-sm xl:pr-44 lg:pr-0 md:pr-0">
                We came from the frustration of the stress of managing the
                finance of the organisation and saving some percentage of our
                income, now we’re here to provide solutions.
              </p>
            </div>

            <div className="xl:pt-0 lg:pt-0 md:pt-12 ">
              <img
                src="../../../assets/images/img@contact-phone.png"
                className="xl:relative lg:relative md:static static xl:top-[300px] lg:top-[150px] md:top-0 top-[300px] right-[30px] xl:w-[450px] lg:w-[500px] md:[400px] w-[400px]"
                width={450}
                alt=""
              />
            </div>

            {/* <div className="md:hidden block bg-center bg-no-repeat bg-cover">
                            <img src="../../../assets/images/img@contact-phone.png" className='md:relative static top-[300px] right-[30px]' width={450} alt="" />
                        </div> */}
          </div>
        </section>

        <section className="xl:pt-0 lg:pt-0 md:pt-0 pt-0 md:pb-0 pb-16">
          <div className="w-screen xl:flex lg:flex md:block block xl:h-[580px] lg:h-[580px] md:h-auto items-center md:bg-white  xl:overflow-x-hidden  lg:overflow-x-hidden md:overflow-auto">
            <div className="md:basis-2/4 flex items-center justify-center xl:mt-0 lg:mt-0 md:mt-12">
              <div className=" pb-16 md:px-16 px-6 ">
                <h1 className="md:text-[53px] text-[32px] pb-6 md:leading-[4rem] leading-tight font-satoshibold capitalize text-oxford-900">
                  Benefits of working with us
                </h1>
                <p className="font-satoshi text-oxford-800 xl:text-base lg:text-2xl md:text-xl text-base xl:pr-32 lg:pr-7 md:pr-32 pr-0">
                  Experience the Difference: Discover the Benefits of Working
                  with Us and Achieve Your financial Goals with Confidence!
                </p>
              </div>
            </div>

            <div
              className="basis-2/4 lg:h-full md:h-[400px] h-[400px] bg-center bg-no-repeat bg-cover relative ml-auto"
              style={{
                backgroundImage: `url("../../../assets/images/bg@about-manage.png")`,
              }}
            ></div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default About;
